import * as $ from 'jquery';

interface IDictionary<T> {
	[details: string] : T;
}

let pageNavContent = $('#pageNavContent');
if (pageNavContent.length) {
	let searchAnchor = window.location.hash && window.location.hash.substr(1) || '';

	let pages: any[] = [];
	let activePage: any = null;
	let pageID = 100
	let pageMapByID: IDictionary<any> = {};

	pageNavContent.children().each(function() {
		if (this.nodeType != 1) {
			return
		}
		let element = $(this)
		let header = element.children('h2,h3').first();
		let title = header.html();
		if (!title) {
			console.error("No title element found")
			return
		}
		let titleAnchor = title
			.replace(/\W+/g, '-')
			.replace(/^-/, '')
			.replace(/-$/, '')
			.toLowerCase()
		let page = {
			id: pageID++,
			title: title,
			anchor: header.attr('id'),
			titleAnchor: titleAnchor,
			element: element,
		}
		pages.push(page)
		pageMapByID[page.titleAnchor] = page;
		element.hide()
		element.find('[id]').each(function() {
			let element = $(this);
			let subID = element.attr('id');
			pageMapByID[subID] = [page, element];
			console.log("sub id: ", subID);
			if (searchAnchor === subID) {
				activePage = page;
				window.setTimeout(() => {
					element[0].scrollIntoView();
				});
			}
		});
		if (page.anchor) {
			pageMapByID[page.anchor] = page;
			header.removeAttr('id')
			element.attr('id', page.anchor)
		}
		console.log('title: ', title, 'id:', page.anchor, 'anchor:', titleAnchor);
		if ((titleAnchor && searchAnchor === titleAnchor) || searchAnchor === page.anchor) {
			activePage = page;
			window.setTimeout(() => {
				activePage.element[0].scrollIntoView();
			});
		}
		//if (!page.anchor) {
		//	console.warn('element without id!')
		//}
	});
	if (!activePage) {
		activePage = pages[0];
	}
	activePage.element.show();

	/*
	const app = Vue.createApp(PageNavigation, {
		translation: (<any>window).translation,
		initialActivePage: activePage,
		pages: pages,
		documentTitle: document.title,
	})
	const vm = app.mount('#pageNav')
	*/
	//console.log(window.location.hash);
	/*
	let pageMenuTpl = jsviews.templates($('#pageMenu').html());
	let pageMenuHtml = pageMenuTpl.render({
		pages: pages,
	});
	console.log(pageMenuHtml)
	 */
	const documentTitle = document.title;

	const activatePage = (page: any, updateHistory: boolean) => {
		console.log(page);
		activePage.element.hide();
		activePage = page;
		activePage.element.show();
		document.title = activePage.title + ' - ' + documentTitle;
		if (updateHistory) {
			let loc = window.location;
			let hash = '#' + (page.anchor || page.titleAnchor);
			let url = loc.protocol + "//" + loc.host + loc.pathname + loc.search + hash;
			window.history.pushState({}, document.title, url);
			$('a.language-link').each(function() {
				let element = $(this);
				element.attr('href', element.attr('data-href') + hash);
				//console.log(this);
			});
		}
		window.setTimeout(() => {
			activePage.element[0].scrollIntoView({ behavior: "smooth" });
		});
		renderNavItems();
	};

	const renderNavItems = () => {
		pages.forEach((page) => {
			if (page.id === activePage.id) {
				page.navItem.addClass('active');
				/*
				page.navItem.children('.symbol').each(function() {
					$(this)
						.removeClass('disabled')
						.addClass('fs-60')
						.empty()
						.append('<i class="fas fa-circle fa-xs"></i>');
				})
				 */
			} else {
				page.navItem.removeClass('active');
				/*
				let symbol = page.id < activePage.id
					? '<i class="fas fa-angle-down fa-xs"></i>'
					: '<i class="fas fa-angle-up fa-xs"></i>';
				page.navItem.children('.symbol').each(function() {
					$(this)
						.removeClass('fs-60')
						.addClass('disabled')
						.empty()
						.append(symbol);
				})
				 */
			}
		});
		activePage.element.find('.loadLazy').each(function() {
			let element = $(this);
			let src = element.data('src');
			if (src) {
				element.attr('src', src).data('src', '').attr('loading', "lazy");
			}
		});
	}

	/*
	window.addEventListener('hashchange', function(event) {
		console.log(event);
		let searchAnchor = window.location.hash && window.location.hash.substr(1) || '';
		pages.forEach((page) => {
			if (searchAnchor === page.titleAnchor || searchAnchor === page.anchor) {
				activatePage(page, false);
				window.scrollTo(0, 0);
				page.element[0].scrollIntoView();
			}
		});
	});
	*/

	const restoreScrollPos = () => {
		if (sessionStorage.getItem("location.href") != location.href) {
			return;
		}
		window.scrollTo(0, parseInt(sessionStorage.getItem("window.scrollY"), 10));
		sessionStorage.setItem("location.href", "");
	}

	window.addEventListener('popstate', function(event) {
		console.log(event);
		let searchAnchor = window.location.hash && window.location.hash.substr(1) || '';
		let page: any;
		if (!searchAnchor) {
			page = pages[0];
		}  else {
			page = pageMapByID[searchAnchor];
			if (!page) {
				console.warn('Unknown page anchor ' + searchAnchor);
				return;
			}
		}
		if (page instanceof Array) {
			let subElement = page[1];
			page = page[0];
			activatePage(page, false);
			subElement[0].scrollIntoView();
		} else {
			activatePage(page, false);
		}
		restoreScrollPos();
		/*
		pages.forEach((page) => {
			if (searchAnchor === page.titleAnchor || searchAnchor === page.anchor) {
				activatePage(page, false);
			}
		});
		*/
	});

	document.title = activePage.title + ' - ' + documentTitle;

	let pageNavigation = $('#pageNav');
	pageNavigation.addClass('pageNavigation');
	//$('<div class="pageNavigation noPrint">');
	//pageNavigation.append($('<div><img src="/assets/vector/splitter-1.svg" class="splitter-1" alt="--------+o~~~o+---------"></div>'))
	for (let index = 0; index < pages.length; index++) {
		(function(index) {
			let page = pages[index];
			if (index > 0) {
				pageNavigation.append($('<div class="ruler"></div>'));
			}
			let navItem = $('<div class="flexRow alignCenter justifyMiddle h4 darkPurple2 navItem">');
			navItem.on('click', () => {
				activatePage(page, true);
			});
			navItem.append($('<div class="pl-2 pr-4 disabled symbol left"></div>'))
			navItem.append($('<div class="flex-1">').html(page.title));
			navItem.append($('<div class="pl-4 pr-2 disabled symbol right"></div>'))
			page.navItem = navItem;
			pageNavigation.append(navItem);
		})(index);
	}
	renderNavItems();
	//$('#pageNav').append(pageNavigation);

	window.setTimeout(function() {
		restoreScrollPos();
	});
}
